<template>
    <header class="col blog-header py-3">
        <div class="logo text-center">
            <img :src="logo" alt="Candy Paradis" />
        </div>
    </header>
</template>
<script>
import logo from "../../assets/img/logo_candy_resize.svg"
export default {
    name: "cp-Header",
    data() {
        return {
            logo,
        }
    },
    components: {},
}
</script>
<style>
.blog-header {
    justify-content: center;
}
.logo {
    width: 280px;
    object-fit: contain;
    margin: 0 auto;
}

@media (min-width: 1024px) {
    header {
        display: flex;
        place-items: center;
    }

    header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }
}

@media (min-width: 395px) {
    header {
        display: flex;
        place-items: center;
    }


    header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }
}
</style>
