<template>
    <section id="info" class="shadow">
        <div class="info--adress item">
            <div class="info--address-text">
                <p>
                    Patisserie de luxe, spécialisée en gâteaux d'événements (mariage, entreprise, goûter de Noël, bâptème,
                    anniversaire ...) qui
                    fabrique exclusivement sur commande, rien ne se perd, fidèle à notre engagement en faveur du
                    développement durable.
                </p>
                <p>
                    Candy Paradis, propose un service de livraison à domicile, sur Paris et sa région (sur devis en fonction
                    de l'adresse exacte sur un
                    créneau de 3h ou sur rendez-vous).
                </p>
            </div>
            <div class="info--img item">
                <img :src="boutique" alt="Candy Paradis" />
            </div>
        </div>
        <div class="storeinformation--content">
            <h3>La boutique</h3>
            <address class="storeinformation">
                <div style="" class="item">
                    <div class="storeinformation--details">
                        <div class="storeinformation--details--adress">
                            <span class="inline"><font-awesome-icon icon="fa-solid fa-location-dot" /></span>
                            <a class="StoreInformation__detail-link ms-2"
                                href="https://maps.google.com/?q=53 rue du rendez-vous, 75012 paris" target="_blank">
                                53 rue du Rendez-vous<br />75012 Paris
                            </a>
                        </div>
                        <div class="storeinformation--details--phone">
                            <span class="inline"><font-awesome-icon icon="fa-solid fa-phone" /></span>
                            <a class="ms-2" href="tel:+33143440275"> +33 1 43 44 02 75</a>
                            <br />
                            <a class="ms-2" href="tel:+33621461588">+33 6 21 46 15 88</a>
                        </div>
                        <div class="storeinformation--details--email">
                            <span class="inline"><font-awesome-icon icon="fa-solid fa-envelope" /></span>
                            <a class="ms-2" href="mailto:candyparadis13@gmail.com?subject=Demande d'informations">contact@candyparadis.com</a>
                        </div>
                    </div>
                </div>
                <div class="storeinformation--details--hours item">
                    <span class="inline"><font-awesome-icon icon="fa-solid fa-clock" /></span>
                    <p class="storeinformation--details--hours--title ms-2">Horaire d'ouverture</p>
                    <p>Mardi au Jeudi: Sur rendez-vous <br />Ouverture au public: <br /><br />Vendredi : 14h - 18h<br />
                        Samedi : 11h30 à 18h</p><p>Nous travaillons uniquement sur commande</p>
                </div>
            </address>
        </div>
    </section>
</template>
<script>
import boutique from "../../assets/img/boutique.jpg"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faPhone, faClock, faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons"
library.add(faPhone, faClock, faLocationDot, faEnvelope)

export default {
    name: "cp-Information",
    data() {
        return {
            boutique,
        }
    },
    components: {
        fontAwesomeIcon: FontAwesomeIcon,
    },
}
</script>
<style lang="scss" scoped>
section#info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 2em 0;
    background-color: white;
    padding: 2em;
    width: 100%;
    border-radius: 1em;

    & .item {
        flex: 1;
        text-align: center;
    }

    .info--adress {
        display: flex;
        gap: 2rem;
    }
    .inline {
        vertical-align: middle;
    }
}

.storeinformation--content h3 {
    margin: 40px 0;
    text-align: center;
}

.storeinformation {
    display: flex;
}

.storeinformation--details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info--address-text {
    margin-bottom: 20px;
    flex-basis: 70%;
}

.storeinformation--details--hours--title {
    display: inline-block;
}

@media (max-width: 769px) {
    section#info {
        flex-direction: column;

        .storeinformation {
            gap: 20px;
            font-size: .9rem;
            display: flex;
            flex-direction: column-reverse;
        }

        .info--adress {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

@media (max-width: 920px) {
    section#info {

        .info--address-text {
            margin-bottom: 20px;
            flex-basis: 50%;
        }
    }
}

@media (max-width: 820px) {
    section#info {
        flex-direction: column;

        .info--address-text {
            margin-bottom: 20px;
            flex-basis: 50%;
        }

        .info--img {
            flex-basis: 33.33%;
        }
    }
}
</style>
